<script>
import { maxLength, url } from 'vuelidate/lib/validators';
import { inArray } from '@shared/config/vuelidate';
import storeHelpersMixin from '@shared/mixins/storeHelpers';

export default {
  mixins: [storeHelpersMixin],
  form: {
    accept_marketing_enabled: {
      label: 'Activez la récolte d\'email pour le marketing (RGPD)',
      switchLabel: 'Activer',
      type: 'switch',
      column: 'is-12',
      errors: ['inArray'],
    },
    accept_marketing_text: {
      label: 'Personnalisez le texte d\'acceptation du marketing (RGPD)',
      type: 'textarea',
      column: 'is-12',
      errors: [{ name: 'maxLength', args: [255] }],
      inputAttrs: {
        placeholder: 'Je veux recevoir la newsletter ... OU Je veux être tenu.e au courant...',
      },
    },
    privacy_url: {
      label: 'Lien vers votre politique de confidentialité',
      type: 'text',
      column: 'is-12',
      errors: ['url'],
      inputAttrs: {
        placeholder: 'https://monsite.fr/lien',
      },
    },
    terms_url: {
      label: 'Lien vers vos conditions générales',
      type: 'text',
      column: 'is-12',
      errors: ['url'],
      inputAttrs: {
        placeholder: 'https://monsite.fr/lien',
      },
    },
    terms_text: {
      label: 'Personnalisez le texte d\'acceptation des conditions générales',
      type: 'textarea',
      column: 'is-12',
      errors: [{ name: 'maxLength', args: [255] }],
      inputAttrs: {
        placeholder: 'Acceptez nos conditions générales et politique de confidentialité pour continuer. Liens ci-dessous...',
      },
    },
  },
  data() {
    return {
      isLoading: false,
      store: {
        accept_marketing_enabled: '',
        accept_marketing_text: '',
        privacy_url: '',
        terms_url: '',
        terms_text: '',
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    authUser() {
      return this.$store.getters['auth/user'];
    },
    legalContent() {
      return this.replaceStoreInfos(this.authStore, this.authUser);
    },
  },
  validations: {
    store: {
      accept_marketing_enabled: { inArray: inArray([true, false]) },
      accept_marketing_text: { maxLength: maxLength(255) },
      privacy_url: { url },
      terms_url: { url },
      terms_text: { maxLength: maxLength(255) },
    },
  },
  created() {
    Object.keys(this.store)
      .forEach((k) => (this.store[k] = this.authStore[k]));
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('auth/updateStore', { ...this.store })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        RGPD et liens légaux
      </h2>
      <p>
        Le RGPD impose de communiquer à vos apprenants vos conditions générales et votre politique de confidentialité.
      </p>
      <p>
        Par défaut, nous affichons des conditions générales et politique de confidentialité types à vos apprenants.
        Néanmoins, nous vous <strong>conseillons de rédiger les vôtres</strong> et d'ajouter les liens vers celles-ci.
      </p>
      <p>Tous les champs sont facultatifs.</p>
      <hr>
      <div class="content">
        <h2 class="is-size-5">
          Voir les pages générées
        </h2>
        <p>
          Cliquez sur les liens ci-dessous pour visualisez les conditions générales
          et la politique de confidentialité qui seront affichées à vos apprenants.
        </p>
        <ul>
          <li>
            <BaseShowCmsPage
              class="tdecoration-underline"
              page="cgv-du-formateur"
              :replaceContent="legalContent">
              Conditions générales
            </BaseShowCmsPage>
          </li>
          <li>
            <BaseShowCmsPage
              class="tdecoration-underline"
              page="confidentialite-du-formateur"
              :replaceContent="legalContent">
              Politique de confidentialité
            </BaseShowCmsPage>
          </li>
        </ul>
      </div>
    </div>

    <div class="column">
      <form novalidate class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
            <BaseField v-model="store[k]" :v="$v.store[k]" :field="field" />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
